import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * Component for the unauthorized menu in the website header.
 *
 * The UnauthorizedMenu component displays the menu options for users who are not logged in.
 * It includes options for accessing the home page, planning and inviting,
 * as well as accessing resources. The menu items are rendered using NavLink from React Router,
 * allowing users to navigate to different pages within the website.
 * The component is used within the Header component to display the unauthorized menu when the user is not logged in.
 *
 * ```tsx
 *
 *   <UnauthorizedMenu />
 *
 * ```
 *
 * Function implementation
 *
 * ```tsx
 *
 * function UnauthorizedMenu(): React.JSX.Element {
 *  /...
 * }
 *
 * ```
 *  @returns {JSX.Element} The rendered component
 *
 * @Category Component
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AuthorizedMenu(props: any): React.JSX.Element {
  const { isMobile } = props;
  return (
    <ul className={`navbar-nav me-auto navbar-nav-scroll${isMobile ? ' mobile_navigation' : ''}`}>
      <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
        <NavLink
          className="nav-link item d-flex gap-3 align-items-center"
          style={{ textDecoration: 'none', color: '#414448' }}
          to="/">
          <span className="menu_group">
            <i className="fal fa-home icon-mobile"></i>Home
          </span>
        </NavLink>
      </li>

      <li className="nav-item dropdown">
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ textDecoration: 'none', color: '#414448' }}>
          <span className="menu_group">
            <i className="fal fa-photo-video icon-mobile"></i>Digital <br></br>Invites
          </span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <h4 className="desktop_hide menu_header_title">Digital Invites</h4>

          <li>
            <NavLink className=" dropdown-item" to="/invitations">
              <div className="title_mediumLight">Create Digital Invitation Cards</div>
              <span>Use Designer Templates</span>
            </NavLink>
          </li>
          <li>
            <NavLink className=" dropdown-item" to="/video-invitations">
              <div className="title_mediumLight">Create Digital Video Invitations</div>
              <span>Use Designer Templates</span>
            </NavLink>
          </li>
          <li>
            <NavLink className=" dropdown-item" to="/custom-design-form">
              <div className="title_mediumLight">Custom Invitations</div>
              <span>Fill Form to get your Custom Design </span>
            </NavLink>
          </li>
          <li>
            <NavLink className=" dropdown-item" to="/save-the-date">
              <div className="title_mediumLight">Save the Date Templates </div>
              <span>Inform Guests about Wedding Dates</span>
            </NavLink>
          </li>
        </ul>
        <div className="desktop_hide float_overflow"></div>
      </li>

      <li className="nav-item dropdown">
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ textDecoration: 'none', color: '#414448' }}>
          <span className="menu_group">
            <i className="fal fa-clipboard-user icon-mobile"></i>Manage <br></br>Invitations
          </span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <h4 className="desktop_hide menu_header_title">Manage Invitations</h4>
          <li>
            <NavLink to="/guests" className=" dropdown-item">
              <div className="title_mediumLight">Guest List</div>
              <span>Organised Guest list </span>
              {/* <span>Organize all the guests </span> */}
            </NavLink>
          </li>
          <li>
            <NavLink className=" dropdown-item" to="/events">
              <div className="title_mediumLight">Events</div>
              <span>Create or Manage Your Events </span>
            </NavLink>
          </li>
        </ul>
        <div className="desktop_hide float_overflow"></div>
      </li>

      {/* <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
        <NavLink
          className="item nav-link d-flex gap-3 align-items-center"
          style={{ textDecoration: 'none', color: '#414448' }}
          to="/video-invitations">
          <span className="menu_group">
            <i className="fal fa-photo-video icon-mobile"></i> Video <br></br>Invitation
          </span>
        </NavLink>
      </li> */}

      <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
        <NavLink
          className=" nav-link item d-flex gap-3 align-items-center"
          style={{ textDecoration: 'none', color: '#414448' }}
          to="/manage-website">
          <span className="menu_group">
            <i className="fal fa-browser icon-mobile"></i> Wedding <br></br>Website
          </span>
        </NavLink>
      </li>
      <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
        <NavLink
          className="nav-link item d-flex gap-3 align-items-center"
          style={{ textDecoration: 'none', color: '#414448' }}
          to="/vendors">
          <span className="menu_group">
            <i className="fal fa-store icon-mobile"></i>Vendors
          </span>
        </NavLink>
      </li>
      <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
        <NavLink
          className="nav-link item d-flex gap-3 align-items-center"
          style={{ textDecoration: 'none', color: '#414448' }}
          to="/blog">
          <span className="menu_group">
            <i className="fal fa-photo-video icon-mobile"></i>Blog
          </span>
        </NavLink>
      </li>
    </ul>
  );
}
export default AuthorizedMenu;
