import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login-reducer';
import eventSlice from '../../views/Events/slice/event-slice';
import guestSlice from '../../views/Guests/slice/guest.slice';
import editorSlice from '../../views/Editor/slice/editor.slice';
import adminDesignSlice from '../../views/WeddingPlan/slice/admin-design.slice';
import invitationSlice from '../../views/Invitation/slice/invitation.slice';
import invitationDrawerTitleSlice from './InvitationDrawerTitle';
import addressCollectorSlice from '../../views/AddressCollector/slice/address-collector.slice';
import userProfileSlice from 'views/Profile/slice/profile.slice';
import messageSlice from 'views/Messages/slice/message.slice';
import slambookSlice from 'views/SlamBook/slice/slambook.slice';
import saveTheDateDesignSlice from 'views/SaveTheDate/slice/save-the-date.slice';
import vendorSlice from 'views/Vendor/slice/vendor-slice';
import websiteSlice from 'views/WeddingWebsite/slice/website-slice';
import videoSlice from 'views/VideoInvitation/slice/video-invitation.slice';
import orderSlice from 'views/Orders/slice/order.slice';
import blogSlice from 'views/Blog/slice/blog-slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    guest: guestSlice,
    event: eventSlice,
    editor: editorSlice,
    adminDesign: adminDesignSlice,
    invitation: invitationSlice,
    text: invitationDrawerTitleSlice,
    addressCollector: addressCollectorSlice,
    userProfile: userProfileSlice,
    messages: messageSlice,
    slambook: slambookSlice,
    saveTheDate: saveTheDateDesignSlice,
    vendors: vendorSlice,
    website: websiteSlice,
    video: videoSlice,
    order: orderSlice,
    posts: blogSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
