import React from 'react';
import FloatingIcon from '@viteplan/components/common/FloatingIcons/FloatingIcon';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { contactUsCommunication } from './services/contactUsForm.service';

const contactFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  message: Yup.string().required('Message is required'),
  mobile: Yup.string()
    .min(10, 'Phone number must be at least 10 digits')
    .required('Mobile number is required'),
  captcha: Yup.string().required('Captcha is required')
});

const ContactUs = () => {
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const formInitialState = {
    name: '',
    email: '',
    message: '',
    mobile: '',
    captcha: ''
  };

  return (
    <>
      <section className="contact_us_section section-bg-pink">
        <div className="container">
          <div className="contact_info_container">
            <div className="map">
              <div
                className="contact_form_container"
                style={{ maxWidth: '600px', margin: '0 auto' }}>
                <h3 className="text-center">We'd love to hear from you!</h3>
                <p className="pb-3 text-center">
                  Please fill out the form below and we will get in touch with you as soon as
                  possible.
                </p>
                <Formik
                  initialValues={formInitialState}
                  validationSchema={contactFormValidationSchema}
                  onSubmit={async (values, { resetForm }) => {
                    try {
                      const response = await contactUsCommunication(values);
                      if (response.data) {
                        toast.success('Your message has been sent successfully!', {
                          position: toast.POSITION.TOP_RIGHT
                        });
                        resetForm();
                        recaptchaRef.current?.reset();
                      } else {
                        toast.error('Server Error');
                      }
                    } catch (e) {
                      toast.error('Error Occurred');
                    }
                  }}>
                  {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
                      <div className="field_container">
                        <label className="field_title">Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="name" component="span" className="field_error" />
                      </div>
                      <div className="field_container">
                        <label className="field_title">Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="email" component="span" className="field_error" />
                      </div>

                      <div className="field_container">
                        <label className="field_title">Mobile</label>
                        <input
                          type="text"
                          name="mobile"
                          placeholder="Enter your mobile number"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="mobile" component="span" className="field_error" />
                      </div>
                      <div className="field_container">
                        <label className="field_title">Message</label>
                        <textarea
                          style={{ height: '80px' }}
                          name="message"
                          placeholder="Enter your message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          rows={8}
                        />
                        <ErrorMessage name="message" component="span" className="field_error" />
                      </div>
                      <div className="field_container">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          className="capcha"
                          sitekey="6LeCkMcqAAAAAFcqRl4_EM3qc64qIah6lDkpFnrE" // V3  sitekey="6LckfcAqAAAAAFsuNqgaPnp3iFMgXdmmldFvusjG"
                          onChange={(value: string | null) => setFieldValue('captcha', value)}
                        />
                        <ErrorMessage name="captcha" component="span" className="field_error" />
                      </div>
                      <button type="submit" className="btn primary">
                        Submit
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="text_container">
              <h3 className="text-center remove-br-mobile">Contact Us</h3>
              <p>Viteplan.com</p>
              <p>B-297 Sector 7, Dwarka New Delhi, 110075 India</p>
              <div className="d-flex gap-2 flex-column">
                <a
                  className="btn primary small"
                  href="mailto:support@viteplan.com"
                  style={{ minWidth: '60px' }}>
                  support@viteplan.com
                </a>
                <a
                  className="btn primary small"
                  href="tel:+91 8826493300"
                  style={{ minWidth: '60px' }}>
                  +91 8826493300
                </a>
              </div>
            </div>
          </div>

          {/* ) : (
            <p className="text-center">Please log in to send a message.</p>
          )} */}
        </div>
      </section>
      <FloatingIcon />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};

export default ContactUs;
