import React, { lazy, useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import RequireAuth from './components/auth/RequireAuth';
import './scss/style.css';
import './scss/v1/main.css';
import { useGlobalDrawerContext } from 'context-api/GlobalDrawerContext';
import UnauthorizedMenu from './components/Header/Menu/UnauthorizedMenu';
import { loggedInUser } from './components/auth/auth.service';
import AuthorizedMenu from './components/Header/Menu/AuthorizedMenu';
import PageNotFound from 'views/404';
import ContactUs from 'views/ContactUs/ContactUs';

/**
 * Lazy Loaded Routers
 */
const Home = lazy(() => import('./views/Home/Home'));
const WeddingPlan = lazy(() => import('./views/WeddingPlan/WeddingPlan'));
const GuestList = lazy(() => import('./views/Guests/GuestList'));
const Faq = lazy(() => import('./views/faq/Faq'));
const Terms = lazy(() => import('./views/TermsAndPrivacy/Terms'));
const Customize = lazy(() => import('./views/Customize/Customize'));
const EventPage = lazy(() => import('./views/Events/EventPage'));
const ScrollToTop = lazy(() => import('./components/common/ScrollToTop/ScrollToTop'));
// const Striper = lazy(() => import('./components/common/Striper'));
const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./components/Footer/Footer'));

// const WordAge = lazy(() => import('./views/wordAge/WordAge'));
const WeddingWebsite = lazy(() => import('./views/WeddingWebsite/WeddingWebsite'));
const ManageWebsite = lazy(() => import('./views/WeddingWebsite/manage-website/ManageWebsite'));
const Profile = lazy(() => import('./views/Profile/Profile'));
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));
// const Guests = lazy(() => import('./views/Guests/Guests'));
const GuestNew = lazy(() => import('./views/Guests/GuestNew'));
const Events = lazy(() => import('./views/Events/Events'));
const Editor = lazy(() => import('./views/Editor/Editor'));
const Checkout = lazy(() => import('./views/Checkout/Checkout'));
const Invitations = lazy(() => import('./views/Invitation/Invitations'));
const SaveTheDate = lazy(() => import('./views/SaveTheDate/SaveTheDate'));
const InvitationDetails = lazy(() => import('./views/Invitation/InvitationDetails'));
const RequestInformationCampaign = lazy(
  () => import('./views/Guests/partials/RequestInformationCampaign')
);
const GuestMessages = lazy(() => import('./views/Messages/GuestMessages'));

const PrepareInvitationEditor = lazy(() => import('./views/Editor/PrepareInvitationEditor'));
const PrepareSaveTheDateEditor = lazy(
  () => import('./views/SaveTheDate/partials/PrepareSaveTheDateEditor')
);
const VideoSlideEdit = lazy(() => import('./views/VideoInvitation/partials/VideoSlideEdit'));
const Vendor = lazy(() => import('./views/Vendor/Vendor'));

const SlamBook = lazy(() => import('./views/SlamBook/SlamBook'));
const WebsiteView = lazy(() => import('./views/WeddingWebsite/website-view/WebsiteView'));
const WebsitePreview = lazy(() => import('./views/WeddingWebsite/website-preview/WebsitePreview'));
const Greeting = lazy(() => import('./views/SlamBook/Greeting'));
const VideoInvitaion = lazy(() => import('views/VideoInvitation/VideoInvitation'));
const VideoInvitationPreview = lazy(() => import('views/VideoInvitation/VideoInvitationPreview'));
const OrderTracking = lazy(() => import('views/Orders/OrderTracking'));
const Cart = lazy(() => import('views/Orders/Cart'));
const Blog = lazy(() => import('views/Blog/Blog'));
const SinglePost = lazy(() => import('views/Blog/SinglePost'));
// const PageNotFound = lazy(() => import('views/404'));

/**
 * Full Page Routers
 */
const LoginNew = lazy(() => import('./views/Credentials/LoginNew'));
const Register = lazy(() => import('./views/Credentials/Register'));
const VendorLogin = lazy(() => import('./views/Vendor/VendorLogin'));
const VendorSignup = lazy(() => import('./views/Vendor/VendorSignup'));
const EmailVerification = lazy(() => import('./views/Credentials/EmailVerification'));
const VerifyAccount = lazy(() => import('./views/Credentials/VerifyAccount'));
const VerifyVendorAccount = lazy(() => import('./views/Vendor/Credentials/VerifyVendorAccount'));
const AddressCollector = lazy(() => import('./views/AddressCollector/AddressCollector'));
const ForgotPassword = lazy(() => import('./views/Credentials/ForgotPassword'));
const VendorForgotPassword = lazy(() => import('./views/Vendor/Credentials/VendorForgotPassword'));
const ForgotPasswordVerification = lazy(
  () => import('./views/Credentials/ForgotPasswordVerification')
);
const VendorForgotPasswordVerification = lazy(
  () => import('./views/Vendor/Credentials/VendorForgotPasswordVerification')
);
const ResetPassword = lazy(() => import('./views/Credentials/ResetPassword'));
const VendorResetPassword = lazy(() => import('./views/Vendor/Credentials/VendorResetPassword'));
const GuestInvitation = lazy(() => import('./views/Invitation/GuestInvitation'));
const CollaboratorPasswordUpdate = lazy(
  () => import('./views/Profile/partials/CollaboratorPasswordUpdate')
);
const InvitationPreview = lazy(() => import('./views/InvitationPreview/InvitationPreview'));
//const Vendor = lazy(() => import('./views/Vendor/Vendor'));
const CustomDesignForm = lazy(() => import('./views/CustomDesignForm/CustomDesignForm'));
const VendorRegistrationSucess = lazy(() => import('./views/Vendor/VendorRegistrationSucess'));
const GuestView = lazy(() => import('./views/GuestView/GuestView'));
const WeddingClientQuestionnaire = lazy(
  () => import('./views/CustomDesignForm/WeddingClientQuestionnaire')
);
const QuestionnaireSuccess = lazy(() => import('./views/CustomDesignForm/QuestionnaireSuccess'));
const SaveTheDatePreview = lazy(() => import('./views/SaveTheDate/SaveTheDatePreview'));
const SaveTheDateList = lazy(() => import('./views/SaveTheDate/SaveTheDateList'));
const VendorDetails = lazy(() => import('./views/Vendor/partials/VendorDetails'));
const VendorProfile = lazy(() => import('./views/Vendor/VendorProfile'));
const VendorPublicProfile = lazy(() => import('./views/Vendor/partials/VendorPublicProfile'));
const Payment = lazy(() =>
  import('./views/Payments/Payment').then((module) => ({ default: module.Payment }))
);
const VerifyVideoInvitationCustomerDetails = lazy(
  () => import('./views/VideoInvitation/partials/VerifyVideoInvitationReqCustomer')
);
const ProcessingPayment = lazy(() => import('./views/Payments/ProcessingPayment'));

const PaymentSuccess = lazy(() => import('./views/Payments/PaymentSuccess'));
const PaymentError = lazy(() => import('./views/Payments/PaymentError'));
const VideoSigupSuccess = lazy(() => import('./views/VideoInvitation/VideoSigupSuccess'));

/**
 * @function App
 * The main App component.
 * @returns {React.JSX.Element} The rendered component.
 */
function App(): React.JSX.Element {
  // const loggedIn = useSelector((state: RootState) => state.login.loggedIn);
  const location = useLocation();
  const { hideDrawer } = useGlobalDrawerContext();
  const pathName = location.pathname;
  const fullPageRoutes = [
    '/login',
    '/vendor/login',
    '/register',
    '/vendor/login',
    '/vendor/register',
    '/vendor/success',
    '/demo',
    '/signup-success',
    '/user/forgot-password',
    '/user/reset-password',
    '/addr/:eventGroupCode',
    '/user/forgot-password-success',
    '/verify/:verificationCode',
    '/verify-vendor/:verificationCode',
    '/user/reset-password/:verificationCode',
    '/user/collaborator/invite/:uniqueId',
    '/invitation/guest-invitation/:XXXINVITATION_ID',
    '/web/:eventGroupCode',
    '/invitation-preview',
    '/wedding-client-questionnaire-success',
    '/vendor/forgot-password',
    '/vendor/forgot-password-success',
    '/vendor/reset-password/:verificationCode'
  ];

  const footerDisabledRoute = ['/invitations/wedding/editor'];

  /**
   * Checks if the given path name corresponds to a full-screen route.
   * @param {string} pathName - The path name to check.
   * @returns {boolean} True if the path name corresponds to a full-screen route; otherwise, false.
   */
  const isFullScreenRoute = (pathName: string) => {
    return fullPageRoutes.some((route) => {
      const routeRegex = new RegExp('^' + route.replace(/:[\w\-&]+/g, '([\\w-&]+)') + '$');
      return routeRegex.test(pathName);
    });
  };

  const isFooterDisableRoute = (pathName: string) => {
    return footerDisabledRoute.some((route) => {
      const routeRegex = new RegExp('^' + route.replace(/:[\w\-&]+/g, '([\\w-&]+)') + '$');
      return routeRegex.test(pathName);
    });
  };

  const isFullScreen = isFullScreenRoute(pathName);
  const isFooterDisable = isFooterDisableRoute(pathName);

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1300);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      hideDrawer();
    };

    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <>
      {/* <ViewSetup /> */}
      <ScrollToTop />
      {!isFullScreen && (
        <>
          {/* <Striper /> */}
          <Header isLargeScreen={isLargeScreen} />
        </>
      )}
      <div className={!isFullScreen ? 'root_container' : 'login_fullPage_container'}>
        <Routes>
          {/** Public Route */}
          <Route path="/" element={<Home />} />
          <Route path="/invitations" element={<WeddingPlan />} />
          <Route path="/save-the-date" element={<SaveTheDate />} />
          <Route path="/guests" element={<GuestList />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/invitations/wedding/customize/:id" element={<Customize />} />
          <Route path="/events" element={<EventPage />} />
          {/* <Route path="/wordage" element={<WordAge />} /> */}
          <Route path="/wedding-website" element={<WeddingWebsite />} />
          <Route path="/guest-view" element={<GuestView />}>
            <Route path="addr/:eventGroupCode" element={<AddressCollector />} />
            <Route path="messages/:eventGroupCode" element={<GuestMessages />} />
            <Route path="memories/:eventGroupCode" element={<SlamBook />} />
          </Route>
          <Route path="/vendors" element={<Vendor />} />
          <Route path="/vendors/:sector/:city" element={<VendorDetails />} />
          <Route path="/vendors/profile/:uniqueKey" element={<VendorPublicProfile />} />
          <Route path="/wedding-client-questionnaire" element={<WeddingClientQuestionnaire />} />
          <Route path="/video-invitations" element={<VideoInvitaion />} />
          <Route path="/video-invitations/preview/:url" element={<VideoInvitationPreview />} />
          <Route path="/video-invitations-edit-slides/:url" element={<VideoSlideEdit />} />
          <Route
            path="/verify-video-invitation-details/:orderId"
            element={<VerifyVideoInvitationCustomerDetails />}
          />
          <Route path="/processing-payment" element={<ProcessingPayment />} />
          <Route path="/video-signup-success" element={<VideoSigupSuccess />} />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          {/** Blog Route */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<SinglePost />} />
          <Route path="/contact-us" element={<ContactUs />} />
          {/** Private Route */}
          {/* TODO: Use Roles find to protect based on roles */}
          <Route element={<RequireAuth role={'HOST'} />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manage-website" element={<ManageWebsite />} />
            {/* <Route path="/manage-guests" element={<Guests />} /> */}
            <Route path="/manage-guests-new" element={<GuestNew />} />
            <Route path="/manage-events" element={<Events />} />
            <Route path="/invitations/wedding/editor" element={<Editor />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/manage-invitations" element={<Invitations />} />
            <Route path="/save-the-date-preview/:templateId" element={<SaveTheDatePreview />} />
            <Route path="/manage-save-the-date" element={<SaveTheDateList />} />
            <Route path="/invitation-details/:id" element={<InvitationDetails />} />
            <Route path="/request-info-campaign" element={<RequestInformationCampaign />} />
            <Route path="/perpare-invitation-editor/:id" element={<PrepareInvitationEditor />} />
            <Route
              path="/perpare-save-the-date-editor/:id"
              element={<PrepareSaveTheDateEditor />}
            />

            <Route path="/manage-greetings" element={<Greeting />} />
            <Route path="/custom-design-form" element={<CustomDesignForm />} />
            <Route path="/website-preview" element={<WebsitePreview />} />
            <Route path="/order-tracking" element={<OrderTracking />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/payment/error" element={<PaymentError />} />
            <Route path="/cart" element={<Cart />} />
          </Route>
          <Route element={<RequireAuth role={'Vendor'} />}>
            {' '}
            <Route path="/vendor-profile" element={<VendorProfile />} />
          </Route>
          {isFullScreen && (
            <>
              {/* <div
                className="login_fullPage_container"
                style={{ position: 'absolute', width: '100vw', height: '100vh' }}> */}
              <Route path="/login" element={<LoginNew />} />
              <Route path="/register" element={<Register />} />
              <Route path="/vendor/login" element={<VendorLogin />} />
              <Route path="/vendor/register" element={<VendorSignup />} />
              <Route path="/verify/:verificationCode" element={<VerifyAccount />} />
              <Route path="/verify-vendor/:verificationCode" element={<VerifyVendorAccount />} />
              <Route path="/signup-success" element={<EmailVerification />} />
              <Route path="/addr/:eventGroupCode" element={<AddressCollector />} />
              <Route path="/user/forgot-password" element={<ForgotPassword />} />
              <Route path="/vendor/forgot-password" element={<VendorForgotPassword />} />
              <Route
                path="/user/forgot-password-success"
                element={<ForgotPasswordVerification />}
              />
              <Route
                path="/vendor/forgot-password-success"
                element={<VendorForgotPasswordVerification />}
              />
              <Route path="/user/reset-password/:verificationCode" element={<ResetPassword />} />
              <Route
                path="/vendor/reset-password/:verificationCode"
                element={<VendorResetPassword />}
              />
              <Route
                path="/user/collaborator/invite/:uniqueId"
                element={<CollaboratorPasswordUpdate />}
              />
              <Route
                path="/invitation/guest-invitation/:XXXINVITATION_ID"
                element={<GuestInvitation />}
              />
              <Route path="/memories/:eventGroupCode" element={<SlamBook />} />
              <Route path="/web/:eventGroupCode" element={<WebsiteView />} />
              <Route path="/invitation-preview" element={<InvitationPreview />} />
              <Route path="/vendor/success" element={<VendorRegistrationSucess />} />
              <Route
                path="/wedding-client-questionnaire-success"
                element={<QuestionnaireSuccess />}
              />
              {/* </div> */}
            </>
          )}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      {!isFullScreen && !isFooterDisable && <Footer />}
      {/* {isFullScreen && (
        <>
          <div
            className="login_fullPage_container"
            style={{ position: 'absolute', width: '100vw', height: '100vh' }}>
            <Routes>
              <Route path="/login" element={<LoginNew />} />
              <Route path="/register" element={<Register />} />
              <Route path="/vendor/login" element={<VendorLogin />} />
              <Route path="/vendor/register" element={<VendorSignup />} />
              <Route path="/verify/:verificationCode" element={<VerifyAccount />} />
              <Route path="/verify-vendor/:verificationCode" element={<VerifyVendorAccount />} />
              <Route path="/signup-success" element={<EmailVerification />} />
              <Route path="/addr/:eventGroupCode" element={<AddressCollector />} />
              <Route path="/user/forgot-password" element={<ForgotPassword />} />
              <Route path="/vendor/forgot-password" element={<VendorForgotPassword />} />
              <Route
                path="/user/forgot-password-success"
                element={<ForgotPasswordVerification />}
              />
              <Route
                path="/vendor/forgot-password-success"
                element={<VendorForgotPasswordVerification />}
              />
              <Route path="/user/reset-password/:verificationCode" element={<ResetPassword />} />
              <Route
                path="/vendor/reset-password/:verificationCode"
                element={<VendorResetPassword />}
              />
              <Route
                path="/user/collaborator/invite/:uniqueId"
                element={<CollaboratorPasswordUpdate />}
              />
              <Route
                path="/invitation/guest-invitation/:XXXINVITATION_ID"
                element={<GuestInvitation />}
              />
              <Route path="/memories/:eventGroupCode" element={<SlamBook />} />
              <Route path="/web/:eventGroupCode" element={<WebsiteView />} />
              <Route path="/invitation-preview" element={<InvitationPreview />} />
              <Route path="/vendor/success" element={<VendorRegistrationSucess />} />
              <Route
                path="/wedding-client-questionnaire-success"
                element={<QuestionnaireSuccess />}
              />
            </Routes>
          </div>
        </>
      )} */}
      {!isLargeScreen && !loggedInUser() && !isFullScreen && <UnauthorizedMenu isMobile={true} />}
      {!isLargeScreen && loggedInUser() && !isFullScreen && <AuthorizedMenu isMobile={true} />}
    </>
  );
}

export default App;
