import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postList } from '../services/blog.service';
import { formatPostData } from '../services/blog-helper.service';

export const getPosts = createAsyncThunk('postList', async () => {
  const response = await postList();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = response.data.map((post: any) => formatPostData(post));
  return data;
});

interface BlogState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posts: any;
  loading: boolean;
  error: null | string;
}

const initialState: BlogState = {
  posts: [],
  loading: false,
  error: null
};

export const blogSlice = createSlice({
  name: 'blogSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GET ALL
    builder.addCase(getPosts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.posts = action.payload;
      // state.posts = data;
    });
    builder.addCase(getPosts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message as string;
    });
  }
});
export default blogSlice.reducer;
