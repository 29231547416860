import React from 'react';

const FloatingIcon = () => {
  return (
    <div>
      <a
        href="https://wa.me/918826493300?text=Hi%20I%20am%20looking%20for%20the%20Wedding%20invites
          "
        className="fix_aside_whatapp">
        <i className="fab fa-whatsapp"></i>
      </a>
    </div>
  );
};

export default FloatingIcon;
