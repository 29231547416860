import axios, { AxiosResponse } from 'axios';
import { formatPostData } from './blog-helper.service';

export const postList = () => {
  // const baseURL = process.env.WP_BASE_URL;
  const url = 'https://viteplan.com/blogs/wp-json/wp/v2/posts?_embed';
  return axios.get(url, {}).then((res: AxiosResponse) => res);
};

export const getPostBySlug = (slug: string | undefined) => {
  const url = `https://viteplan.com/blogs/wp-json/wp/v2/posts?slug=${slug}&_embed`;
  return axios.get(url, {}).then((res: AxiosResponse) => res.data[0]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRelatedPosts = (post: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const categoryIds = post.categories.map((category: any) => category.id);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tagIds = post.tags.map((tag: any) => tag.id);

  console.log(categoryIds, '----->', tagIds);
  const url = `https://viteplan.com/blogs/wp-json/wp/v2/posts?tags=${tagIds.join(',')}&exclude=${
    post.id
  }&per_page=3&_embed`;
  return (
    axios
      .get(url, {})
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: AxiosResponse) => res.data.map((post: any) => formatPostData(post)))
  );
};
