// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatPostData = (post: any) => {
  return {
    id: post.id,
    title: post.title,
    slug: post.slug,
    excerpt: post.excerpt,
    content: post.content,
    date: post.date,
    featuredImage: post._embedded?.['wp:featuredmedia']?.[0]?.source_url || null,
    author: post._embedded?.author?.[0]?.name || null,
    categories:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      post._embedded?.['wp:term']?.[0]?.map((category: any) => ({
        id: category.id,
        name: category.name,
        slug: category.slug
      })) || [],
    tags:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      post._embedded?.['wp:term']?.[1]?.map((tag: any) => ({
        id: tag.id,
        name: tag.name,
        slug: tag.slug
      })) || []
  };
};
