import httpService from '@viteplan/services/http.service';

export const checkGuestExistInEventGroup = (eventGroupId: number, emailId: string, config = {}) => {
  // Define the base URL
  const url = `guest/check-guest?eventGroupId=${eventGroupId}&email=${encodeURIComponent(emailId)}`;

  return (
    httpService
      .get(url, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

export const getGuestGroupByGuestEmailId = (eventGroupId: number, emailId: string, config = {}) => {
  // Define the base URL
  const url = `guest/group-by-email?eventGroupId=${eventGroupId}&email=${encodeURIComponent(
    emailId
  )}`;

  return (
    httpService
      .get(url, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGuestGroup = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post(`guest/group`, body, config).then((res: any) => res);
};

export const getRequestInfoTemplate = (eventUniqueKey: string | undefined, config = {}) => {
  return (
    httpService
      .get(`request-info-template/eg/${eventUniqueKey}`, config)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addRequestInfoTemplate = (body: any, config = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return httpService.post('request-info-template', body, config).then((res: any) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGuestByCID = async (cid: any) => {
  const response = await fetch(`https://api.yavun.com/api/transactionalGuest/${cid}`);
  const data = await response.json();
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateRequestInfoWeddingDate = async (eventGroupId: number | undefined, body: any) => {
  return (
    httpService
      .post(`request-info-template/${eventGroupId}/weddingDate`, body)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
  );
};
